import ErgAuthService from '@ergeon/erg-auth';

type OptionalSettings = {
  cookieName?: string;
};

const COOKIE_PARAM_NAME = 'cookieName';
const OPTIONAL_SETTINGS: OptionalSettings = {};
if (process.env.REACT_APP_AUTH_COOKIE_NAME) {
  OPTIONAL_SETTINGS[COOKIE_PARAM_NAME] = process.env.REACT_APP_AUTH_COOKIE_NAME;
}

export const authService = new ErgAuthService({
  ...OPTIONAL_SETTINGS,
  appId: 'ergeon',
  idService: `${process.env.API_HOST_SERVICE_ID}/`,
  userDetailsEndpoint: `${process.env.API_HOST}/api/v1/authenticate/admin/`,
  level: process.env.NODE_ENV,
});

export default authService;
