import React, {Suspense} from 'react';

import {Redirect, Route, Router, Switch} from 'react-router-dom';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {createBrowserHistory} from 'history';

import Loader from '../components/common/Loader';
import AuthWrapper from '../components/AuthWrapper';
import {METADATA_DESCRIPTION} from '../constants';
import * as Sentry from '../utils/sentry';
import {getAppEnv} from '../utils/utils';
import ErrorBoundary from '../components/common/ErrorBoundary';

// eslint-disable-next-line import/no-unresolved
import '@ergeon/core-components/dist/main.css';

const history = createBrowserHistory();

Sentry.init(history);
const SentryRoute = Sentry.withSentryRouting(Route);

const appEnv = getAppEnv();
export const SMALL_FAVICON_PATH = `../assets/${appEnv}-favicon-16.svg`;
export const MEDIUM_FAVICON_PATH = `../assets/${appEnv}-favicon-48.svg`;

const Dashboard = React.lazy(() => import('../components/Dashboard'));
const DraftEditor = React.lazy(() => import('../components/DraftEditorWrapper'));
const StyleBrowser = React.lazy(() => import('../components/StyleBrowserWrapper'));
const Sandbox = React.lazy(() => import('../components/SandboxWrapper'));
const Shortener = React.lazy(() => import('../components/ShortenerWrapper'));
const StyleEditor = React.lazy(() => import('../components/StyleEditorWrapper'));
const StyleSelector = React.lazy(() => import('../components/StyleSelectorWrapper'));
const MaterialList = React.lazy(() => import('../components/MaterialListWrapper'));
const RulesGallery = React.lazy(() => import('../components/RulesGalleryWrapper'));
const CatalogBrowser = React.lazy(() => import('../components/CatalogBrowserWrapper'));
const MapViewer = React.lazy(() => import('../components/MapViewerWrapper'));
const InventoryGallery = React.lazy(() => import('../components/InventoryGalleryWrapper'));

const App = () => {
  return (
    <Router history={history}>
      <HelmetProvider>
        <Helmet defaultTitle="Ergeon" titleTemplate="%s | Ergeon">
          <link href={SMALL_FAVICON_PATH} rel="icon" sizes="16x16" type="image/svg+xml" />
          <link href={MEDIUM_FAVICON_PATH} rel="icon" sizes="48x48" type="image/svg+xml" />
          <meta content={METADATA_DESCRIPTION} name="description"></meta>
        </Helmet>
        <Suspense fallback={<Loader />}>
          <ErrorBoundary>
            <AuthWrapper>
              <Switch>
                <SentryRoute component={Shortener} path="/shortener" />
                <SentryRoute component={StyleBrowser} path="/style-browser" />
                <SentryRoute component={StyleEditor} path="/style-editor" />
                <SentryRoute component={StyleSelector} path="/style-selector" />
                <SentryRoute component={Sandbox} path="/sandbox" />
                <SentryRoute component={() => <Redirect to="/draft-editor" />} path="/layout-editor" />
                <SentryRoute component={MaterialList} path="/material-list" />
                <SentryRoute component={DraftEditor} path="/draft-editor" />
                <SentryRoute component={RulesGallery} path="/rules-gallery" />
                <SentryRoute component={CatalogBrowser} path="/catalog-browser" />
                <SentryRoute component={MapViewer} path="/map-viewer" />
                <SentryRoute component={InventoryGallery} path="/inventory-gallery" />
                <SentryRoute component={Dashboard} path="/" />
              </Switch>
            </AuthWrapper>
          </ErrorBoundary>
        </Suspense>
      </HelmetProvider>
    </Router>
  );
};

export default App;
