import React from 'react';

import {Spinner} from '@ergeon/core-components';
import './Loader.scss';

const Loader = () => {
  return (
    <div className="full-screen-loader" data-testid="loader">
      <Spinner active borderWidth={0.16} color="blue" size={64} />
    </div>
  );
};

export default Loader;
