import React from 'react';

import PropTypes from 'prop-types';

import * as Sentry from '../../../utils/sentry';

import ErrorFallbackScreen from './components/ErrorFallbackScreen';

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return {hasError: true};
  }

  static propTypes = {
    children: PropTypes.node,
  };
  // @ts-ignore
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(err: any) {
    const error = err instanceof Error ? err : new Error(JSON.stringify(err));
    Sentry.captureException(error);
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      return <ErrorFallbackScreen
        description="We are already notified about this. Please try to refresh the page."
        title="Oops, something unexpected happened"
      />;
    }
    // @ts-ignore
    return this.props.children;
  }
}

export default ErrorBoundary;
