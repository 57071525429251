export enum MENU_LIST_TITLES {
  STYLE_BROWSER = 'Style Browser',
  STYLE_EDITOR = 'Style Editor',
  STYLE_SELECTOR = 'Style Selector',
  DRAFT_EDITOR = 'Draft Editor',
  RULES_GALLERY = 'Rules Gallery',
  MAP_VIEWER = 'Map Viewer',
  CATALOG_BROWSER = 'Catalog Browser',
  URL_SHORTENER = 'URL Shortener',
  MINI_MATERIAL_LIST = 'Mini Material List',
  SANDBOX = 'Sandbox',
  INVENTORY_GALLERY = 'Inventory Gallery',
}

export const METADATA_DESCRIPTION = 'A procedural generation of 3d CAD models for outdoor home improvements';

export const SENTRY_DSN = 'https://fed7858e3f4e4a15a5df8ae720f072aa@o147577.ingest.sentry.io/4503919735144448';
