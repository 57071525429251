import React from 'react';

import {User} from '@ergeon/erg-auth';

export interface IAuthContextInterface {
  user: User;
}

const AuthContext = React.createContext<IAuthContextInterface>({} as IAuthContextInterface);

export default AuthContext;
