import React from 'react';

import errorFallbackImage from '../../../../../assets/whale-in-a-pool-next-to-fence.png';

import './ErrorFallbackScreen.scss';

export interface ErrorFallbackScreenProps {
  title: string;
  description?: string;
  image?: string;
 }

const ErrorFallbackScreen = (props: ErrorFallbackScreenProps) => {
  const {title, image = errorFallbackImage, description} = props;

  return (
    <div className="fallback-screen">
      <img
        alt="Default error icon"
        className="error-fallback-image"
        data-error="true"
        data-loading="false"
        data-testid="Error-fallbackScreen"
        src={image}
      />
      <div>
        <h2 className="center spacing before__is-30">{title}</h2>
        <p className="spacing before__is-24 center">{description}</p>
      </div>
    </div>
  );
};

export default ErrorFallbackScreen;
