import {ReactNode} from 'react';

import {Auth} from '@ergeon/core-components';
import {User} from '@ergeon/erg-auth';

import authService from '../../utils/auth';

import AuthContext from './AuthContext';

import './AuthWrapper.scss';

type AuthWrapperProps = {
  children: ReactNode;
};

export const AuthWrapper = ({children}: AuthWrapperProps) => (
  <div className="auth-wrapper" data-testid="auth-wrapper">
    <Auth authService={authService}>
      {(user: User) => <AuthContext.Provider value={{user}}>{children}</AuthContext.Provider>}
    </Auth>
  </div>
);

export default AuthWrapper;
