import * as Sentry from '@ergeon/erg-utils-js/exports/sentry';
import type { RouterHistory } from '@ergeon/erg-utils-js/exports/sentry';

import {SENTRY_DSN} from '../constants';

export { captureException, withSentryRouting } from '@ergeon/erg-utils-js/exports/sentry';

export const init = (history: RouterHistory) => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: process.env.NODE_ENV,
      release: process.env.SENTRY_RELEASE_NAME,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
      tracesSampleRate: 0.05,
    });
  }
};
